import React, { useState, useEffect } from "react";
import {
  Box,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  IconButton,
  Flex,
  Tooltip,
} from "@chakra-ui/react";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { jdnuToHumanReadable } from "../utils/jdnu";

interface TimeSliderProps {
  jdnuRange: [string, string];
  selectedJDNU: string;
  onChange: (jdnu: string) => void;
}

const TimeSlider: React.FC<TimeSliderProps> = ({
  jdnuRange,
  selectedJDNU,
  onChange,
}) => {
  const [startJDNU, endJDNU] = jdnuRange;
  const [startJDN, endJDN] = [
    Number(startJDNU.split(":")[0]),
    Number(endJDNU.split(":")[0]),
  ];
  const jdnuDates = Array.from(
    { length: Math.ceil((endJDN - startJDN + 1) / 30) },
    (_, index) => {
      const jdn = startJDN + index * 30;
      const uncertainty = "E";
      return `${jdn}:${uncertainty}`;
    }
  );

  const initialIndex = jdnuDates.findIndex((jdnu) => jdnu === selectedJDNU);
  const [currentIndex, setCurrentIndex] = useState(
    initialIndex !== -1 ? initialIndex : 0
  );

  const [showTooltip, setShowTooltip] = useState(false);

  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) =>
      Math.min(prevIndex + 1, jdnuDates.length - 1)
    );
  };

  const handleThumbDrag = (value: number) => {
    setCurrentIndex(value);
  };

  useEffect(() => {
    try {
      const currentJDNU = jdnuDates[currentIndex];
      onChange(currentJDNU);
    } catch (e) {
      console.error(e);
    }
  }, [currentIndex, jdnuDates, onChange]);

  return (
    <Box width="100%" mb={3} mt={3}>
      <Flex alignItems="center" position="relative" paddingX={10}>
        <IconButton
          aria-label="Previous"
          icon={<ChevronLeft />}
          onClick={handlePrevClick}
          isDisabled={currentIndex === 0}
          size="sm"
          variant="outline"
          mr={2}
          borderRadius="full"
        />
        <Box flex={1} position="relative">
          <Slider
            value={currentIndex}
            min={0}
            max={jdnuDates.length - 1}
            onChange={handleThumbDrag}
            focusThumbOnChange={false}
            width="100%"
            colorScheme="teal"
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
          >
            <SliderTrack bg="gray.300">
              <SliderFilledTrack bg="teal.500" />
            </SliderTrack>
            <Tooltip
              hasArrow
              bg="teal.500"
              color="white"
              placement="top"
              isOpen={showTooltip}
              label={jdnuToHumanReadable(jdnuDates[currentIndex], false)}
            >
              <SliderThumb boxSize={6} />
            </Tooltip>
          </Slider>
        </Box>
        <IconButton
          aria-label="Next"
          icon={<ChevronRight />}
          onClick={handleNextClick}
          isDisabled={currentIndex === jdnuDates.length - 1}
          size="sm"
          variant="outline"
          borderRadius="full"
          ml={2}
        />
      </Flex>
    </Box>
  );
};

export default TimeSlider;
