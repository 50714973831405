import { useEffect, ReactNode } from "react";

interface FullPageWrapperProps {
  children: ReactNode;
}

const FullPageWrapper = ({ children }: FullPageWrapperProps) => {
  useEffect(() => {
    const { documentElement, body } = document;

    documentElement.style.overflow = "hidden";
    documentElement.style.width = "100%";
    body.style.height = "100%";
    body.style.position = "fixed";
    body.style.overflowY = "scroll";
    (body.style as any).WebkitOverflowScrolling = "touch";

    return () => {
      documentElement.style.overflow = "";
      documentElement.style.width = "";
      body.style.height = "";
      body.style.position = "";
      body.style.overflowY = "";
      (body.style as any).WebkitOverflowScrolling = "";
    };
  }, []);

  return <>{children}</>;
};

export default FullPageWrapper;
