export const jdnToGregorian = (jdn: number): string => {
  const a = jdn + 32044;
  const b = Math.floor((4 * a + 3) / 146097);
  const c = a - Math.floor((146097 * b) / 4);
  const d = Math.floor((4 * c + 3) / 1461);
  const e = c - Math.floor((1461 * d) / 4);
  const m = Math.floor((5 * e + 2) / 153);
  const day = e - Math.floor((153 * m + 2) / 5) + 1;
  const month = m + 3 - 12 * Math.floor(m / 10);
  const year = 100 * b + d - 4800 + Math.floor(m / 10);
  return `${year < 1 ? `BC ${Math.abs(year - 1)}` : `${year}`}-${month
    .toString()
    .padStart(2, "0")}-${day.toString().padStart(2, "0")}`;
};

export const jdnuToHumanReadable = (
  jdnu?: string,
  includeUncertainty: boolean = true
): string => {
  if (!jdnu) {
    return "";
  }
  const [jdn, uncertainty] = jdnu.split(":");
  const gregorianDate = jdnToGregorian(Number(jdn));
  if (!includeUncertainty) {
    return gregorianDate;
  }
  return `${gregorianDate} (${uncertainty})`;
};

export const gregorianToJdnu = (
  gregorianDate: string,
  uncertainty: string = "U"
): string => {
  const dateParts = gregorianDate.split("-");
  const year = parseInt(dateParts[0], 10);
  const month = dateParts[1] ? parseInt(dateParts[1], 10) : 1;
  const day = dateParts[2] ? parseInt(dateParts[2], 10) : 1;

  let a = Math.floor((14 - month) / 12);
  let y = year + 4800 - a;
  let m = month + 12 * a - 3;

  let jdn =
    day +
    Math.floor((153 * m + 2) / 5) +
    365 * y +
    Math.floor(y / 4) -
    Math.floor(y / 100) +
    Math.floor(y / 400) -
    32045;

  if (year < 1) {
    jdn = -jdn + 1;
  }

  return `${jdn}:${uncertainty}`;
};

/*
  E: Exact date (e.g., 2456789:E)
  Y: Year only (e.g., 2456789:Y)
  D: Decade (e.g., 2456789:D)
  C: Century (e.g., 2456789:C)
  A: Approximate date (e.g., 2456789:A)
  U: Unknown or uncertain date (e.g., 2456789:U)
  */
