import { useCallback, useEffect, useState } from "react";
import {
  MapContainer,
  TileLayer,
  LayersControl,
  useMapEvents,
  ScaleControl,
  WMSTileLayer,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { TileLayerConfig } from "../hooks/useTileConfigList";
import { envConfig } from "../envConfig";
import L from "leaflet";
import MapModal from "./ClickModal";

const defaultPosition = { lat: 51.505, lng: -0.09 };
const defaultZoom = 2;

interface GeoJsonMapProps {
  containerStyle: { width: string; height: string };
  tileLayerConfigs: TileLayerConfig[];
  selectedJDNU: string;
}

const GeoJsonMap = ({
  containerStyle,
  tileLayerConfigs,
  selectedJDNU,
}: GeoJsonMapProps) => {
  const [map, setMap] = useState<L.Map | null>(null);
  const [position, setPosition] = useState(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const urlLat = urlParams.get("lat");
    const urlLng = urlParams.get("lng");
    if (urlLat && urlLng) {
      return { lat: parseFloat(urlLat), lng: parseFloat(urlLng) };
    }
    const storedPosition = localStorage.getItem("mapPosition");
    return storedPosition ? JSON.parse(storedPosition) : defaultPosition;
  });
  const [zoom, setZoom] = useState(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const urlZoom = urlParams.get("zoom");
    if (urlZoom) {
      return parseInt(urlZoom, 10);
    }
    const storedZoom = localStorage.getItem("mapZoom");
    return storedZoom ? parseInt(storedZoom, 10) : defaultZoom;
  });
  const [selectedTileLayer, setSelectedTileLayer] = useState(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const urlTileLayer = urlParams.get("tileLayer");
    if (urlTileLayer) {
      return urlTileLayer;
    }
    const storedTileLayer = localStorage.getItem("selectedTileLayer");
    return storedTileLayer || tileLayerConfigs[0].id;
  });
  const [selectedOverlayLayers, setSelectedOverlayLayers] = useState<string[]>(
    () => {
      const urlParams = new URLSearchParams(window.location.search);
      const urlOverlayLayers = urlParams.get("overlayLayers");
      if (urlOverlayLayers) {
        return urlOverlayLayers.split(",");
      }
      const storedOverlayLayers = localStorage.getItem("selectedOverlayLayers");
      return storedOverlayLayers ? JSON.parse(storedOverlayLayers) : [];
    }
  );
  const [debounceTimeout, setDebounceTimeout] = useState<NodeJS.Timeout | null>(
    null
  );

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [clickedPosition, setClickedPosition] = useState<L.LatLng | null>(null);

  const updateUrl = useCallback(
    (tileLayer?: string, overlayLayers?: string[]) => {
      if (map) {
        const newPosition = map.getCenter();
        const newZoom = map.getZoom();
        const newTileLayer = tileLayer || selectedTileLayer;
        const newOverlayLayers = overlayLayers || selectedOverlayLayers;

        localStorage.setItem("mapPosition", JSON.stringify(newPosition));
        localStorage.setItem("mapZoom", newZoom.toString());
        localStorage.setItem("selectedTileLayer", newTileLayer);
        localStorage.setItem(
          "selectedOverlayLayers",
          JSON.stringify(newOverlayLayers)
        );

        const url = new URL(window.location.href);
        url.searchParams.set("lat", newPosition.lat.toString());
        url.searchParams.set("lng", newPosition.lng.toString());
        url.searchParams.set("zoom", newZoom.toString());
        url.searchParams.set("tileLayer", newTileLayer);
        url.searchParams.set("overlayLayers", newOverlayLayers.join(","));

        window.history.replaceState(null, "", url.toString());
      }
    },
    [map, selectedTileLayer, selectedOverlayLayers]
  );

  const onMove = useCallback(() => {
    if (map) {
      const newPosition = map.getCenter();
      const newZoom = map.getZoom();
      setPosition(newPosition);
      setZoom(newZoom);
    }
  }, [map]);

  useEffect(() => {
    if (map) {
      map.setView(position, zoom);
      map.on("move", onMove);
    }
    return () => {
      if (map) {
        map.off("move", onMove);
      }
    };
  }, [map, onMove, position, zoom]);

  const MapEvents = () => {
    useMapEvents({
      click: (e: L.LeafletMouseEvent) => {
        if (map) {
          const clickedPosition = e.latlng;
          const { lat, lng } = clickedPosition;

          // Normalize longitude to the range -180 to 180 degrees
          const normalizedLng = ((lng + 540) % 360) - 180;

          const normalizedPosition: L.LatLng = new L.LatLng(lat, normalizedLng);
          setClickedPosition(normalizedPosition);
          setIsModalOpen(true);
        }
      },
      moveend: () => {
        if (map) {
          if (debounceTimeout) {
            clearTimeout(debounceTimeout);
          }

          setDebounceTimeout(setTimeout(updateUrl, 500));
        }
      },
      zoomend: () => {
        if (map) {
          const newZoom = map.getZoom();
          setZoom(newZoom);

          if (debounceTimeout) {
            clearTimeout(debounceTimeout);
          }

          setDebounceTimeout(setTimeout(updateUrl, 500));
        }
      },
      baselayerchange: (e) => {
        // Find the tile layer config that matches the name and set the ID not the label
        const tileLayerConfig = tileLayerConfigs.find(
          (config) => config.label === e.name
        );
        if (!tileLayerConfig) {
          console.error("Tile layer config not found for", e.name);
          return;
        }
        setSelectedTileLayer(tileLayerConfig.id);
        updateUrl(tileLayerConfig.id);
      },
      overlayadd: (e) => {
        const tileLayerConfig = tileLayerConfigs.find(
          (config) => config.label === e.name
        );
        if (!tileLayerConfig) {
          console.error("Tile layer config not found for", e.name);
          return;
        }

        const newOverlayLayers = [...selectedOverlayLayers, tileLayerConfig.id];
        setSelectedOverlayLayers(newOverlayLayers);
        updateUrl(undefined, newOverlayLayers);
      },
      overlayremove: (e) => {
        const tileLayerConfig = tileLayerConfigs.find(
          (config) => config.label === e.name
        );
        if (!tileLayerConfig) {
          console.error("Tile layer config not found for", e.name);
          return;
        }

        const newOverlayLayers = selectedOverlayLayers.filter(
          (layer) => layer !== tileLayerConfig.id
        );
        setSelectedOverlayLayers(newOverlayLayers);
        updateUrl(undefined, newOverlayLayers);
      },
    });

    return null;
  };

  return (
    // https://en.wikipedia.org/wiki/Web_Map_Service
    // https://leafletjs.com/reference.html#map-option
    <>
      <MapContainer
        style={containerStyle}
        center={position}
        zoom={zoom}
        maxZoom={18}
        minZoom={0}
        ref={setMap}
        preferCanvas
      >
        <MapEvents />
        <ScaleControl imperial={false} position="bottomleft" />
        <LayersControl position="topright" autoZIndex={false}>
          {tileLayerConfigs
            .filter((option) => option.type === "overlay")
            .map((option) => (
              <LayersControl.Overlay
                key={option.id}
                name={option.label || option.id}
                checked={selectedOverlayLayers.includes(option.id)}
              >
                {/* https://leafletjs.com/reference.html#tilelayer */}
                <TileLayer
                  pane="tilePane"
                  url={`${envConfig.TILE_SERVER_PROXY}/tile/${option.id}/{z}/{x}/{y}/${selectedJDNU}`}
                  attribution={option.attribution}
                  zIndex={20}
                  updateInterval={1000}
                  maxNativeZoom={option.maxZoom}
                  minNativeZoom={option.minZoom}
                  opacity={option.opacity || 1}
                  className={option.id}
                />
              </LayersControl.Overlay>
            ))}
          {tileLayerConfigs
            .filter((option) => {
              return option.type === "base";
            })
            .sort((a, b) => {
              // Sort by label, then id
              return (
                (a.label || "").localeCompare(b.label || "") ||
                a.id.localeCompare(b.id)
              );
            })
            .map((option) => (
              <LayersControl.BaseLayer
                key={option.id}
                name={option.label || option.id}
                checked={option.id === selectedTileLayer}
              >
                {/* https://leafletjs.com/reference.html#tilelayer */}
                <TileLayer
                  url={`${envConfig.TILE_SERVER_PROXY}/tile/${option.id}/{z}/{x}/{y}/${selectedJDNU}`}
                  attribution={option.attribution}
                  zIndex={10}
                  updateInterval={1000}
                  maxNativeZoom={option.maxZoom}
                  minNativeZoom={option.minZoom}
                  className={option.id}
                />
              </LayersControl.BaseLayer>
            ))}
          {tileLayerConfigs
            .filter((option) => option.type === "wms")
            .map((option) => (
              <LayersControl.Overlay
                key={option.id}
                name={option.label || option.id}
                checked={selectedOverlayLayers.includes(option.id)}
              >
                <WMSTileLayer
                  url={option.urls[0]}
                  layers={option.layers}
                  format={option.format}
                  transparent={true}
                  attribution={option.attribution}
                  zIndex={20}
                  updateInterval={1000}
                  maxNativeZoom={option.maxZoom}
                  minNativeZoom={option.minZoom}
                  opacity={option.opacity || 1}
                  className={option.id}
                />
              </LayersControl.Overlay>
            ))}
        </LayersControl>
      </MapContainer>
      <MapModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        position={clickedPosition}
        checkedLayers={selectedOverlayLayers}
        jdnu={selectedJDNU}
      />
    </>
  );
};

export default GeoJsonMap;
