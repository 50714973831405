import React from "react";
import ReactDOM from "react-dom";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Box,
  Text,
} from "@chakra-ui/react";
import { useTooltipData } from "../hooks/useTooltipData";

interface MapModalProps {
  isOpen: boolean;
  onClose: () => void;
  position: L.LatLng | null;
  checkedLayers: string[];
  jdnu: string;
}

const MapModal: React.FC<MapModalProps> = ({
  isOpen,
  onClose,
  position,
  checkedLayers,
  jdnu,
}) => {
  const {
    data: tooltipData,
    isLoading,
    error,
  } = useTooltipData(
    position?.lat || 0,
    position?.lng || 0,
    jdnu,
    checkedLayers
  );

  if (!position) return null;

  return ReactDOM.createPortal(
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Info</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box>
            {/* ... */}
            {isLoading ? (
              <Text>Loading tooltip data...</Text>
            ) : error ? (
              <Text>Error loading tooltip data: {error.message}</Text>
            ) : tooltipData ? (
              Object.entries(tooltipData).map(([layer, value]) => (
                <Text key={layer}>
                  {layer}: {value !== null ? value : "No data"}
                </Text>
              ))
            ) : null}
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>,
    document.body
  );
};

export default MapModal;
