import { useState, useEffect } from "react";
import { Flex, Text } from "@chakra-ui/layout";
import TimeSlider from "./TimeSlider";
import GeoJsonMap from "./GeoJsonMap";
import { useTileLayerConfigs } from "../hooks/useTileConfigList";
import { gregorianToJdnu, jdnuToHumanReadable } from "../utils/jdnu";
import { throttle } from "lodash";
import { Button } from "@chakra-ui/react";
import { JdnuRangeModal } from "./JdnuRangeModal";
import FullPageWrapper from "./FullPageWrapper";

const MapComponent = () => {
  const {
    data: tileLayerConfigs,
    isLoading: isTileLayerConfigsLoading,
    isError: isTileLayerConfigsError,
    error: tileLayerConfigsError,
  } = useTileLayerConfigs();

  const defaultJdnuRange: [string, string] = [
    gregorianToJdnu("1950-01-01", "E"),
    gregorianToJdnu("2024-04-24", "E"),
  ];
  const defaultSelectedJDNU = gregorianToJdnu("2024-04-20", "E");

  const [jdnuRange, setJdnuRange] = useState(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const urlJdnuRange = urlParams.get("jdnuRange");
    if (urlJdnuRange) {
      const [start, end] = urlJdnuRange.split(",");
      return [start, end];
    }
    const storedJdnuRange = localStorage.getItem("jdnuRange");
    return storedJdnuRange ? JSON.parse(storedJdnuRange) : defaultJdnuRange;
  });

  const [selectedJDNU, setSelectedJDNU] = useState(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const urlSelectedJDNU = urlParams.get("selectedJDNU");
    if (urlSelectedJDNU) {
      return urlSelectedJDNU;
    }
    const storedSelectedJDNU = localStorage.getItem("selectedJDNU");
    return storedSelectedJDNU || defaultSelectedJDNU;
  });

  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set("jdnuRange", jdnuRange.join(","));
    urlParams.set("selectedJDNU", selectedJDNU);
    window.history.replaceState(null, "", `?${urlParams.toString()}`);
    localStorage.setItem("jdnuRange", JSON.stringify(jdnuRange));
    localStorage.setItem("selectedJDNU", selectedJDNU);
  }, [jdnuRange, selectedJDNU]);

  const handleDateChange = (jdnu: string) => {
    if (selectedJDNU === jdnu) {
      return;
    }
    const prev = selectedJDNU;
    setSelectedJDNU(jdnu);
    console.log("Selected JDNU:", jdnu, "old JDNU:", prev);
  };

  const throttledHandleDateChange = throttle(handleDateChange, 500);

  const handleSaveJdnuRange = (start: string, end: string) => {
    setJdnuRange([start, end]);
  };

  if (isTileLayerConfigsLoading) {
    return <div>Loading tile layer configs...</div>;
  }

  if (isTileLayerConfigsError) {
    return (
      <div>
        Error loading tile layer configs: {tileLayerConfigsError.message}
      </div>
    );
  }

  if (!tileLayerConfigs) {
    return <div>No tile layer configs found</div>;
  }

  return (
    <FullPageWrapper>
      <Flex width="100vw" height="100vh" flexDir="column">
        <Flex bg="blue" width="100%" flex="1 1 auto" minHeight={0}>
          <GeoJsonMap
            containerStyle={{ width: "100%", height: "100%" }}
            tileLayerConfigs={tileLayerConfigs}
            selectedJDNU={selectedJDNU}
          />
        </Flex>
        <Flex width="100%" flexShrink={0} flexDir="column">
          <Flex
            width="100%"
            minHeight="44px"
            backgroundColor="darkslateblue"
            alignItems="center"
            justifyContent="space-between"
            px={4}
          >
            <Text>Current: {jdnuToHumanReadable(selectedJDNU, false)}</Text>
            <Button size="sm" onClick={() => setIsModalOpen(true)}>
              Time Range
            </Button>
          </Flex>
          <TimeSlider
            jdnuRange={jdnuRange}
            selectedJDNU={selectedJDNU}
            onChange={throttledHandleDateChange}
          />
        </Flex>
      </Flex>
      <JdnuRangeModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSave={handleSaveJdnuRange}
        currentEndDate={jdnuRange[1]}
        currentStartDate={jdnuRange[0]}
      />
    </FullPageWrapper>
  );
};

export default MapComponent;
