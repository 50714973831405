import React from "react";
import "./App.css";
import MapComponent from "./components/MapComponent";
import { Box, ChakraProvider } from "@chakra-ui/react";
import { theme } from "./design/theme";
import { QueryClientProvider, QueryClient } from "react-query";

const queryClient = new QueryClient();

function App() {
  return (
    <ChakraProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <Box w="100%" h="100%">
          <MapComponent />
        </Box>
      </QueryClientProvider>
    </ChakraProvider>
  );
}

export default App;
