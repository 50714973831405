// src/hooks/useTileLayerConfigs.ts
import { useQuery } from "react-query";
import axios from "axios";
import { envConfig } from "../envConfig";

export type JDNUDate = string;
export interface TileLayerConfig {
  id: string;
  label?: string;
  type: "base" | "overlay" | "wms";
  urls: string[];
  layers?: string;
  format?: string;
  attribution: string;
  maxZoom: number;
  minZoom: number;
  gcsBucket: string;
  opacity?: number;
  date?: JDNUDate;
  crs?: "EPSG4326" | "EPSG3857";
}

const fetchTileLayerConfigs = async (): Promise<TileLayerConfig[]> => {
  const response = await axios.get<TileLayerConfig[]>(
    `${envConfig.TILE_SERVER_PROXY}/configs`
  );
  return response.data;
};

export const useTileLayerConfigs = () => {
  return useQuery<TileLayerConfig[], Error>(
    "tileLayerConfigs",
    fetchTileLayerConfigs
  );
};
