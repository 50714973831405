// src/hooks/useTooltipData.ts
import { useQuery } from "react-query";
import axios from "axios";
import { envConfig } from "../envConfig";

interface TooltipData {
  [key: string]: number | null;
}

const fetchTooltipData = async (
  lat: number,
  lon: number,
  jdnuQuery: string,
  layerList: string[]
): Promise<TooltipData> => {
  const response = await axios.get<TooltipData>(
    `${envConfig.TILE_SERVER_PROXY}/tooltip`,
    {
      params: {
        lat,
        lon,
        jdnu_query: jdnuQuery,
        layer_list: layerList,
      },
    }
  );
  return response.data;
};

export const useTooltipData = (
  lat: number,
  lon: number,
  jdnuQuery: string,
  layerList: string[]
) => {
  return useQuery<TooltipData, Error>(
    ["tooltipData", lat, lon, jdnuQuery, layerList],
    () => fetchTooltipData(lat, lon, jdnuQuery, layerList),
    {
      enabled: lat !== null && lon !== null && jdnuQuery !== null,
    }
  );
};
