import { useState } from "react";
import { gregorianToJdnu, jdnToGregorian } from "../utils/jdnu";
import {
  Button,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";

export const JdnuRangeModal = ({
  isOpen,
  onClose,
  onSave,
  currentStartDate,
  currentEndDate,
}: {
  isOpen: boolean;
  onClose: () => void;
  onSave: (start: string, end: string) => void;
  currentStartDate: string;
  currentEndDate: string;
}) => {
  const [startDate, setStartDate] = useState(
    jdnToGregorian(Number(currentStartDate.split(":")[0]))
  );
  const [endDate, setEndDate] = useState(
    jdnToGregorian(Number(currentEndDate.split(":")[0]))
  );

  const handleSave = () => {
    const startJdnu = gregorianToJdnu(startDate, "E");
    const endJdnu = gregorianToJdnu(endDate, "E");
    onSave(startJdnu, endJdnu);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Configure Time Range</ModalHeader>
        <ModalBody>
          <Flex direction="column" gap={4}>
            <Input
              placeholder="Start Date (YYYY-MM-DD)"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
            <Input
              placeholder="End Date (YYYY-MM-DD)"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleSave}>
            Save
          </Button>
          <Button onClick={onClose}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
